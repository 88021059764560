import '@angular/compiler';
import { PortalDatasetGraphEqhlcvyeComponent } from './portal.dataset.graph.eqhlcvye/portal.dataset.graph.eqhlcvye.component';
import { PagePagesEditComponent } from './page.pages.edit/page.pages.edit.component';
import { PortalDatasetGraphElqeifhlComponent } from './portal.dataset.graph.elqeifhl/portal.dataset.graph.elqeifhl.component';
import { PortalSurveyEditComponent } from './portal.survey.edit/portal.survey.edit.component';
import { ComponentChartPieComponent } from './component.chart.pie/component.chart.pie.component';
import { PortalDatasetGraphQugbqbbvComponent } from './portal.dataset.graph.qugbqbbv/portal.dataset.graph.qugbqbbv.component';
import { PortalMacroReportGvtuwkdiComponent } from './portal.macro.report.gvtuwkdi/portal.macro.report.gvtuwkdi.component';
import { PortalShareExpertViewComponent } from './portal.share.expert.view/portal.share.expert.view.component';
import { PortalDatasetGraphGvnxalbjComponent } from './portal.dataset.graph.gvnxalbj/portal.dataset.graph.gvnxalbj.component';
import { PortalCommunityCommentComponent } from './portal.community.comment/portal.community.comment.component';
import { PortalDatasetGraphAcurqyvmComponent } from './portal.dataset.graph.acurqyvm/portal.dataset.graph.acurqyvm.component';
import { PortalDatasetGraphBdbeucfsComponent } from './portal.dataset.graph.bdbeucfs/portal.dataset.graph.bdbeucfs.component';
import { PortalSurveyListComponent } from './portal.survey.list/portal.survey.list.component';
import { PortalSurveyResultComponent } from './portal.survey.result/portal.survey.result.component';
import { PortalDetailExplanationInquireComponent } from './portal.detail.explanation.inquire/portal.detail.explanation.inquire.component';
import { PortalDatasetGraphShhssxveComponent } from './portal.dataset.graph.shhssxve/portal.dataset.graph.shhssxve.component';
import { ComponentSearchPopularityComponent } from './component.search.popularity/component.search.popularity.component';
import { ComponentSearchComponent } from './component.search/component.search.component';
import { ComponentCardNotificationComponent } from './component.card.notification/component.card.notification.component';
import { PortalCommunityViewComponent } from './portal.community.view/portal.community.view.component';
import { PortalDetailSurveyGraphComponent } from './portal.detail.survey.graph/portal.detail.survey.graph.component';
import { PortalDetailExplanationExplanationComponent } from './portal.detail.explanation.explanation/portal.detail.explanation.explanation.component';
import { PortalDatasetGraphDpljpprrComponent } from './portal.dataset.graph.dpljpprr/portal.dataset.graph.dpljpprr.component';
import { ComponentPagenationComponent } from './component.pagenation/component.pagenation.component';
import { PortalDatasetGraphIckwdgwzComponent } from './portal.dataset.graph.ickwdgwz/portal.dataset.graph.ickwdgwz.component';
import { PageShareComponent } from './page.share/page.share.component';
import { PortalShareDriveComponent } from './portal.share.drive/portal.share.drive.component';
import { ComponentCardOfferComponent } from './component.card.offer/component.card.offer.component';
import { PortalDatasetGraphJldlzqnjComponent } from './portal.dataset.graph.jldlzqnj/portal.dataset.graph.jldlzqnj.component';
import { PageSurveyComponent } from './page.survey/page.survey.component';
import { PortalDatasetGraphLpsxfshvComponent } from './portal.dataset.graph.lpsxfshv/portal.dataset.graph.lpsxfshv.component';
import { PagePagesComponent } from './page.pages/page.pages.component';
import { PortalDatasetGraphOfuzpqttComponent } from './portal.dataset.graph.ofuzpqtt/portal.dataset.graph.ofuzpqtt.component';
import { ComponentNavigationSubComponent } from './component.navigation.sub/component.navigation.sub.component';
import { PortalCommunityListComponent } from './portal.community.list/portal.community.list.component';
import { PortalDashboardMedicineComponent } from './portal.dashboard.medicine/portal.dashboard.medicine.component';
import { ComponentCardCaseComponent } from './component.card.case/component.card.case.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { ComponentModalIntroduceComponent } from './component.modal.introduce/component.modal.introduce.component';
import { PageDatasetSearchComponent } from './page.dataset.search/page.dataset.search.component';
import { PortalDetailExplainationYearlyComponent } from './portal.detail.explaination.yearly/portal.detail.explaination.yearly.component';
import { ComponentNavigationComponent } from './component.navigation/component.navigation.component';
import { ComponentGraphNetworkComponent } from './component.graph.network/component.graph.network.component';
import { PortalDashboardChartBarComponent } from './portal.dashboard.chart.bar/portal.dashboard.chart.bar.component';
import { PortalCommunityEditComponent } from './portal.community.edit/portal.community.edit.component';
import { PortalDetailSurveyInquireComponent } from './portal.detail.survey.inquire/portal.detail.survey.inquire.component';
import { PortalDetailExplanationDatacardComponent } from './portal.detail.explanation.datacard/portal.detail.explanation.datacard.component';
import { PageDatasetExplanationComponent } from './page.dataset.explanation/page.dataset.explanation.component';
import { PortalSurveyAnalysisComponent } from './portal.survey.analysis/portal.survey.analysis.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { ComponentLoadingHexaComponent } from './component.loading.hexa/component.loading.hexa.component';
import { PortalDatasetGraphUlwaytdtComponent } from './portal.dataset.graph.ulwaytdt/portal.dataset.graph.ulwaytdt.component';
import { PortalDatasetGraphTcqapgikComponent } from './portal.dataset.graph.tcqapgik/portal.dataset.graph.tcqapgik.component';
import { PortalDashboardDeviceComponent } from './portal.dashboard.device/portal.dashboard.device.component';
import { PortalMacroReportKrdhdtnbComponent } from './portal.macro.report.krdhdtnb/portal.macro.report.krdhdtnb.component';
import { PortalDetailExplanationPreviewComponent } from './portal.detail.explanation.preview/portal.detail.explanation.preview.component';
import { PageCommunityComponent } from './page.community/page.community.component';
import { PortalDashboardSurveyComponent } from './portal.dashboard.survey/portal.dashboard.survey.component';
import { PortalDashboardYearlyGraphComponent } from './portal.dashboard.yearly.graph/portal.dashboard.yearly.graph.component';
import { PortalDatasetGraphMhqcflmdComponent } from './portal.dataset.graph.mhqcflmd/portal.dataset.graph.mhqcflmd.component';
import { PortalDetailExplainationInformationComponent } from './portal.detail.explaination.information/portal.detail.explaination.information.component';
import { PortalMacroRendererComponent } from './portal.macro.renderer/portal.macro.renderer.component';
import { PortalShareUserDetailComponent } from './portal.share.user.detail/portal.share.user.detail.component';
import { LayoutTopnavComponent } from './layout.topnav/layout.topnav.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalDatasetGraphYzimkzitComponent } from './portal.dataset.graph.yzimkzit/portal.dataset.graph.yzimkzit.component';
import { ComponentChartBarLeftComponent } from './component.chart.bar.left/component.chart.bar.left.component';
import { PortalDatasetGraphEfwesxhtComponent } from './portal.dataset.graph.efwesxht/portal.dataset.graph.efwesxht.component';
import { PortalDatasetGraphOqlzxgeaComponent } from './portal.dataset.graph.oqlzxgea/portal.dataset.graph.oqlzxgea.component';
import { PortalDatasetGraphUogbpzanComponent } from './portal.dataset.graph.uogbpzan/portal.dataset.graph.uogbpzan.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalShareExpertDetailComponent } from './portal.share.expert.detail/portal.share.expert.detail.component';
import { PortalDetailSurveyPreviewComponent } from './portal.detail.survey.preview/portal.detail.survey.preview.component';
import { PortalShareUserViewComponent } from './portal.share.user.view/portal.share.user.view.component';
import { PortalDatasetGraphDjcqelizComponent } from './portal.dataset.graph.djcqeliz/portal.dataset.graph.djcqeliz.component';
import { PortalDetailExplainationGraphComponent } from './portal.detail.explaination.graph/portal.detail.explaination.graph.component';
import { PortalDatasetGraphQouvvjomComponent } from './portal.dataset.graph.qouvvjom/portal.dataset.graph.qouvvjom.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PageJoinComponent } from './page.join/page.join.component';
import { MatTreeModule } from '@angular/material/tree';

const INDEX_PAGE = "main";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutTopnavComponent,
        "children": [
            {
                "path": "page/edit/:category",
                component: PagePagesEditComponent,
                "app_id": "page.pages.edit"
            },
            {
                "path": "share/:mode?/:role?/:id?",
                component: PageShareComponent,
                "app_id": "page.share"
            },
            {
                "path": "survey/:mode?/:id?",
                component: PageSurveyComponent,
                "app_id": "page.survey"
            },
            {
                "path": "page/:category",
                component: PagePagesComponent,
                "app_id": "page.pages"
            },
            {
                "path": "login",
                component: PageLoginComponent,
                "app_id": "page.login"
            },
            {
                "path": "dataset/search",
                component: PageDatasetSearchComponent,
                "app_id": "page.dataset.search"
            },
            {
                "path": "dashboard/:category/:id",
                component: PageDatasetExplanationComponent,
                "app_id": "page.dataset.explanation"
            },
            {
                "path": "main",
                component: PageMainComponent,
                "app_id": "page.main"
            },
            {
                "path": "dashboard/:mode?/:id?",
                component: PageDashboardComponent,
                "app_id": "page.dashboard"
            },
            {
                "path": "community/:category?/:mode?/:id?",
                component: PageCommunityComponent,
                "app_id": "page.community"
            },
            {
                "path": "mypage",
                component: PageMypageComponent,
                "app_id": "page.mypage"
            },
            {
                "path": "join",
                component: PageJoinComponent,
                "app_id": "page.join"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }