import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.navigation.sub');
import { OnInit, OnDestroy, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
    selector: 'wiz-component-navigation-sub',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/component.navigation.sub/view.scss */
.text-color {
  color: #4F40F1 !important;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}

.text-default {
  color: #9496A1 !important;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}

.active {
  color: #4F40F1 !important;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}

.ml-page {
  margin-left: 11.5%;
}

.ml-dashboard {
  margin-left: 16%;
}

.ml-share {
  margin-left: 25%;
}

.ml-survey {
  margin-left: 38.3%;
}

.ml-community {
  margin-left: 42.7%;
}

nav.navbar-expand-lg {
  display: flex;
  position: relative;
  max-width: 1920px !important;
  height: auto;
  overflow: hidden;
  flex-direction: column;
  background: #fff;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg {
    display: none;
  }
}
nav.navbar-expand-lg .navbar-brand-container {
  width: 100%;
  display: flex;
  text-align: center;
  background: #fff;
  height: 80px;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-brand-container {
    height: 67px;
  }
}
nav.navbar-expand-lg .navbar-brand-container .navbar-brand {
  flex: auto;
}
nav.navbar-expand-lg .navbar-brand-container .navbar-brand img {
  width: 180px;
  padding-top: 22px;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-brand-container .navbar-brand img {
    width: 128px;
  }
}
nav.navbar-expand-lg .navbar-brand-container .navbar-toggler {
  align-items: flex-end;
  margin-left: 16px;
  margin-right: 16px;
}
nav.navbar-expand-lg .navbar-collapse {
  width: 100%;
  flex: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #fff;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-collapse {
    height: calc(100% - 64px);
    position: fixed;
    top: 64px;
    left: 0;
    overflow-y: auto;
  }
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav {
  margin: 0;
  font-size: 14px;
  overflow-y: auto;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-collapse .navbar-nav {
    display: block;
  }
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav a {
  width: 100%;
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
  cursor: pointer;
  font-family: "main-r";
  margin-left: 24px;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
    margin-left: 0;
  }
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 18px;
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
  color: var(--wc-blue);
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item.active {
  background-color: var(--wc-background);
  border-radius: 10px 0 0 10px;
}`],
})
export class ComponentNavigationSubComponent implements OnInit, OnDestroy, DoCheck {

    public pages_ls = [
        { url: "/page/intro", name: "KMBIG 소개" },
        { url: "/page/explain", name: "보유데이터소개" },
        { url: "/page/help", name: "이용안내" },
        { url: "/page/request", name: "제공신청안내" },
        { url: "/page/term", name: "이용약관" },
        { url: "/page/privacy", name: "개인정보처리방침" }];

    public pages_edit_ls = [
        { url: "/page/edit/intro", name: "KMBIG 소개" },
        { url: "/page/edit/explain", name: "보유데이터소개" },
        { url: "/page/edit/help", name: "이용안내" },
        { url: "/page/edit/request", name: "제공신청안내" },
        { url: "/page/edit/term", name: "이용약관" },
        { url: "/page/edit/privacy", name: "개인정보처리방침" }];

    // public dashboard_ls = [
    //     { url: "/dashboard/device", name: "한의 건강검진 데이터셋" },
    //     { url: "/dashboard/medicine", name: "한약재 데이터셋" },
    //     { url: "/dashboard/multiple", name: "응용 데이터셋" }];

    public dashboard_ls = [];

    public share_ls = [
        { url: "/share/view/user", name: "내 공유요청 목록" },
        { url: "/share/view/expert", name: "전문가 공유심사" },
        { url: "/share/drive", name: "연구자 서랍" }
    ];

    public community_ls = [
        { url: "/community/notice", name: "공지사항" },
        { url: "/community/qna", name: "문의게시판" }];

    constructor(@Inject( Service)         public service: Service,@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Router)         public router: Router,    ) {}

    public currentRoute: any;
    public previousRoute: any;

    public async ngOnInit() {
        await this.service.init();
        if (Object.keys(this.service.auth.session).length === 0 || this.service.auth.session.is_expert == 0) {
            this.share_ls.splice(1, 1);
        }
        this.currentRoute = this.router.url;
        this.menu = this.currentRoute.split("/")[1];

        await this.load();

        this.previousRoute = this.currentRoute;
        await this.service.render();
    }

    public async ngDoCheck() {
        this.currentRoute = this.router.url;
        if (this.currentRoute !== this.previousRoute) {
            this.previousRoute = this.currentRoute;
            this.menu = this.currentRoute.split('/')[1];
            await this.service.render();
        }
    }

    public async load() {
        let { code, data } = await wiz.call("load");
        if (code != 200) return;
        this.dash = data;
        for (let item of this.dash) {
            this.dashboard_ls.push({ url: `/dashboard/${item.category}`, name: `${item.title} 데이터셋` })
        }
        this.username = this.service.auth.session.name;
        this.userrole = this.service.auth.session.role;
        if (this.userrole == 'admin') {
            this.pages_ls = this.pages_edit_ls;
        }
        await this.service.render();
    }
}

export default ComponentNavigationSubComponent;