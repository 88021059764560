import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dashboard.chart.bar');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'wiz-portal-dashboard-chart-bar',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dashboard.chart.bar/view.scss */
.card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.dataset-card {
  height: 100%;
  width: 665px;
}
.dataset-card .dataset-card-list {
  margin-left: 20px;
  margin-top: 15px;
  position: relative;
  flex: 1;
  overflow-y: auto;
}
.dataset-card .dataset-card-list .menu-card {
  width: 117px;
  height: 57px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  text-align: center;
  vertical-align: middle;
  line-height: 57px;
  font-size: 16px;
  font-family: "MAIN-M";
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.dataset-card .dataset-card-list .menu-card2 {
  width: 117px;
  height: 57px;
  background: #4F40F1;
  color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  text-align: center;
  vertical-align: middle;
  line-height: 57px;
  font-size: 16px;
  font-family: "MAIN-B";
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.dataset-card .button_control {
  position: relative;
  margin-top: 2px;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  box-shadow: none;
}
.dataset-card .button_control:hover {
  background-color: rgba(255, 255, 255, 0);
}
.dataset-card .button_control:hover .tooltip {
  opacity: 1;
}
.dataset-card .button_control i {
  display: inline-block;
  vertical-align: top;
}
.dataset-card .button_control .tooltip {
  position: absolute;
  top: -40px;
  left: 275px;
  padding: 5px 12px;
  height: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 15px;
  font-family: "MAIN-B";
  line-height: 27px;
  white-space: nowrap;
  transition: opacity 0.2s;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}

.bar-chart {
  width: 655px;
  height: 567px;
}
.bar-chart .detail {
  width: 140px;
  margin: 20px 10px 0 0;
  color: #4F40F1;
  font-size: 16px;
  border-color: #4F40F1;
  border-radius: 10px;
}
.bar-chart a {
  font-size: 18px;
}

h3 {
  text-align: center;
  margin: 240px 0;
  font-size: 20px;
}`],
})
export class PortalDashboardChartBarComponent implements OnInit {
    // @Input() url: any;
    @Input() dataset_ls: any;
    @Input() device_ls: any;
    @Input() survey_ls: any;

    public link = "wIYqq8BmlyrD5s9Hgvnscs1BtfFzg1mj";
    public datatype = '기기';


    constructor(@Inject( Service    )         public service: Service    ) { }


    public async ngOnInit() {
        await this.service.init();
        const { code, data } = await wiz.call("fileInfo2");
        await this.loadFile();
        // await this.loadGraph();
    }

    public click(url) {
        this.service.href(`/dashboard/device/${url}`);
    }

    public myChart: any;

    public async click2(url) {
        for (let i = 0; i < this.dataset_ls.length; i++) {
            if (url == this.dataset_ls[i].id) {
                this.dataset_ls[i].css_class = "menu-card2";
                this.link = url;
            }
            else this.dataset_ls[i].css_class = "menu-card";
        }
        this.myChart.destroy()
        await this.loadFile();
        await this.service.render();
    }
    public years: any;

    public async loadFile() {
        let { code, data } = await wiz.call("fileInfo", { 'id': this.link });
        if (code == 200) {
            this.datatype = data.datatype;
            this.files = data.cache;
            this.years = Object.keys(this.files);
            this.datas = [];
            this.labels = [];
            for (let key in this.files) {
                if (key == 'all') this.labels.push("ALL")
                else this.labels.push(key);
                this.datas.push(this.files[key]);
            }
        }
        await this.service.render();
        await this.loadGraph();
    }

    public async loadGraph() {
        const data = {
            labels: this.labels,
            datasets: [{
                label: "데이터 건수",
                data: this.datas,
                backgroundColor: [
                    '#DFDEF7',
                    '#C9C5F0',
                    '#B0B0E8',
                    '#4F40F1',
                    '#362BA6'
                ],
                borderColor: [
                    '#DFDEF7',
                    '#C9C5F0',
                    '#B0B0E8',
                    '#4F40F1',
                    '#362BA6'
                ],
                borderWidth: 1,
                borderRadius: 7,
            }]
        };

        let maxValue = Math.max(...this.datas);
        let roundUnit;

        if (maxValue > 10000) {
            roundUnit = 2000;
        } else if (maxValue > 1000) {
            roundUnit = 1000;
        } else if (maxValue > 100) {
            roundUnit = 100;
        } else {
            roundUnit = 10;
        }

        maxValue = Math.ceil(maxValue / roundUnit) * roundUnit + roundUnit;

        const config = {
            type: 'bar',
            data: data,
            options: {
                responsive: false,
                maxBarThickness: 100,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: '#6F6F6F',
                        align: '-90',
                        anchor: 'end',
                    },
                },
                scales: {
                    y: {
                        max: maxValue
                    }
                }
            },
            plugins: [ChartDataLabels],
        };
        const elmt = document.getElementById('widget-bar')
        if (elmt) {
            const ctx = elmt.getContext('2d');
            this.myChart = new Chart(ctx, config);
        }
        await this.service.render();
    }
}

export default PortalDashboardChartBarComponent;