import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dashboard.medicine');
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
    selector: 'wiz-portal-dashboard-medicine',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dashboard.medicine/view.scss */
.container-full {
  min-width: 1920px;
  height: 100%;
  overflow: auto;
}
.container-full .container-sub {
  width: 100%;
  background: no-repeat center/100% url("/assets/images/dashboard/bubble.png");
}
.container-full .container-sub .container-image {
  width: 1780px;
  height: 480px;
  margin-left: auto;
  margin-right: auto;
}
.container-full .container-sub .container-image .content {
  height: 100%;
  width: 100%;
}
.container-full .container-sub .container-image .content .content-menu {
  padding-top: 112px;
}
.container-full .container-sub .container-image .content .content-menu-name {
  padding-top: 15px;
}
.container-full .container-sub .container-image .content .content-content {
  padding-top: 15px;
  width: 922px;
}
.container-full .container-sub .container-image .content .content-content .red {
  color: red;
}
.container-full .container-sub2 {
  width: 100%;
  background: #F7F7FA;
}
.container-full .container-sub2 .container-dataset {
  background: #F7F7FA;
  max-width: 1780px;
  margin: auto;
}
.container-full .container-sub2 .container-dataset .card.card-body {
  border-radius: 15px;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.container-full .container-sub2 .container-dataset .card.card-body h1 {
  font-weight: bold;
  color: blue;
}

.text-menu {
  color: #5B5B6D;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-menu-name {
  color: black;
  font-size: 52px;
  font-weight: bold;
  font-family: "MAIN-B";
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-content {
  color: #404252;
  font-size: 22px;
  line-height: 180%;
  word-break: keep-all;
}

.btn-data {
  border-radius: 999px;
  background: #4F40F1;
  font-family: "MAIN-M";
  color: #FFFFFF;
  width: 166px;
  height: 40px;
  border: 0px;
}`],
})
export class PortalDashboardMedicineComponent implements OnInit {

    public categoryLs = {
        id: "",
        title: "",
        category: "",
        content: ""
    }
    public datasetLs = []
    public datasetID: any;
    public fileInfo: any;

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async load() {
        this.datasetID = this.router.url.split('/')[2];
        let { code, data } = await wiz.call("load", { "id": "한약재" });
        this.datasetLs = data.dataset_row;
        this.categoryLs = data.dashboard_row;
        this.fileInfo = data.fileInfo;
        await this.service.render();
    }

    public async download(file) {
        let download = wiz.url('download?file=' + file)
        window.location.href = download;
    }
}

export default PortalDashboardMedicineComponent;