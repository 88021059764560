import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dashboard.yearly.graph');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-dashboard-yearly-graph',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dashboard.yearly.graph/view.scss */
span {
  font-family: "MAIN-M";
  font-size: 20px;
  color: black;
}

.text-num {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 12px;
}

.text-unit {
  margin-left: 5px;
  font: normal normal normal 16px/55px SUIT;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}`],
})
export class PortalDashboardYearlyGraphComponent implements OnInit {
    @Input() title: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public datatype = '';

    public async ngOnInit() {
        await this.service.init();
        await this.loadFile();
    }

    public async loadFile() {
        let { code, data } = await wiz.call("load");
        this.labels = data.labels;
        this.datas = data.datas;

        this.colors = ['#5041F2', '#F2748D', '#9996B7'];
        await this.service.render();
        await this.loadGraph();
    }

    public async loadGraph() {
        const data = {
            labels: this.labels,
            datasets: [{
                label: '데이터 건수',
                data: this.datas,
                backgroundColor: this.colors,
                borderColor: this.colors,
                borderWidth: 1,
                pointStyle: 'circle',
                datalabels: {
                    color: '#FFFFFF',
                    font: { size: 16 }
                }
            }]
        };

        const config = {
            type: 'polarArea',
            data: data,
            options: {
                responsive: false,
                plugins: {
                    legend: {
                        // display:false,
                        position: 'bottom',
                        labels: {
                            // pointStyle: "circle"
                            usePointStyle: true,
                            color: '#000000',
                            font: { size: 16 }
                        }
                    },
                }
            },
        };

        const actions = [
            {
                name: 'Toggle Point Style',
                handler(myChart) {
                    myChart.options.plugins.legend.labels.usePointStyle = !myChart.options.plugins.legend.labels.usePointStyle;
                    myChart.update();
                }
            },
        ];

        const elmt = document.getElementById('widget-years');

        if (elmt) {
            const ctx = elmt.getContext('2d');
            let myChart = new Chart(ctx, config);

            return () => {
                myChart.destroy();
            }
        }
        await this.service.render();
    }
}

export default PortalDashboardYearlyGraphComponent;