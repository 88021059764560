import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.explanation.preview');
import { OnInit, OnChanges, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-detail-explanation-preview',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.detail.explanation.preview/view.scss */
.container-preview {
  width: 1780px;
  height: auto;
  border-radius: 10px;
  margin: auto;
  margin-top: 30px;
  box-shadow: 0px 3px 24px #E3E6EC;
}
.container-preview .nav-tabs.nav-fill .nav-link {
  border-bottom: none;
  cursor: pointer;
  background: #F7F7F8;
  color: #777986;
  font-size: 24px;
  font-weight: "MAIN-M";
}
.container-preview .nav-tabs.nav-fill .nav-link.active {
  border-bottom: none;
  background: white;
  color: black;
  font-size: 24px;
  font-family: "MAIN-B";
  text-align: center;
}
.container-preview .tab-content {
  height: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.container-preview .tab-content .card {
  height: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.container-preview .tab-content .card span {
  font-size: 24px;
  font-family: "MAIN-B";
}
.container-preview .tab-content .card table {
  margin-top: 18px;
}
.container-preview .tab-content .card table thead {
  height: 40px;
  background: #EDEEF8;
}
.container-preview .tab-content .card table thead th {
  font-size: 16px;
  font-family: "MAIN-B";
  color: black;
  vertical-align: middle;
  background: #EDEEF8;
  border-right: 1px solid #D2D4DA;
}
.container-preview .tab-content .card table thead th td:last-child {
  border-right: white;
}
.container-preview .tab-content .card table tbody tr:last-child {
  border-bottom: white;
}
.container-preview .tab-content .card table tbody tr td {
  border-right: 1px solid #D2D4DA;
}
.container-preview .tab-content .card table tbody tr td:last-child {
  border-right: white;
}
.container-preview .button_control {
  position: relative;
  margin-top: 5px;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  box-shadow: none;
}
.container-preview .button_control:hover {
  background-color: rgba(255, 255, 255, 0);
}
.container-preview .button_control:hover .tooltip {
  opacity: 1;
}
.container-preview .button_control i {
  display: inline-block;
  vertical-align: top;
  opacity: 0.3;
}
.container-preview .button_control .tooltip {
  position: absolute;
  top: -40px;
  left: 170px;
  padding: 5px 12px;
  height: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 15px;
  font-family: "MAIN-B";
  line-height: 27px;
  white-space: nowrap;
  transition: opacity 0.2s;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}

.loading-wrap {
  display: flex;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.text-schema .raw-badge {
  width: 30px;
  height: 20px;
  background-color: #F7D8FB;
}
.text-schema .clean-badge {
  width: 30px;
  height: 20px;
  background-color: #EFFBE0;
}
.text-schema .column-badge {
  width: 30px;
  height: 20px;
  background-color: #BBDCDC;
}`],
})
export class PortalDetailExplanationPreviewComponent implements OnInit, OnChanges {
    @Input() list: any;
    @Input() mode: any;
    @Input() version: any;

    public schema: any;
    public clean: any = [];
    public detail: any = [];
    public role = '';
    public expert = '';

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        // await this.loading(true);
        // await this.file_load();
        // await this.loading(false);
    }

    public ngOnChanges({ version }) {
        if (!version) return;
        const { currentValue } = version;
        if (['all', 'raw', 'cleansing'].includes(currentValue)) {
            this.version = currentValue;
            this.file_load();
        }
    }

    public async file_load() {
        this.dataset_id = WizRoute.segment.id;
        this.category = WizRoute.segment.category;
        let { code, data } = await wiz.call("file_info", { "id": this.dataset_id, "category": this.category, "version": this.version });
        this.file = data.df;
        this.role = data.role;
        this.expert = data.expert; 

        this.schema = JSON.parse(data.schema);

        this.columns = [];
        for (let value of this.file) {
            for (let key in value) {
                if (!this.columns.includes(key)) {
                    this.columns.push(key);
                }
            }
        }

        this.clean = this.columns.map((col) => {
            let item = this.schema.find((item) => item.name === col);
            return item?.cleansing || "";
        });
        this.detail = this.columns.map((col) => {
            let item = this.schema.find((item) => item.name === col);
            return item?.desc || "";
        });

        // if (this.version == 'cleansing') {
        //     this.detail = this.columns.map((col) => {
        //         let item = this.schema.find((item) => item.cleansing === col);
        //         return item?.desc || "";
        //     });
        // } else {
        //     this.detail = this.columns.map((col) => {
        //         let item = this.schema.find((item) => item.name === col);
        //         return item?.desc || "";
        //     });
        // }

        await this.service.render();
    }

    public tab(mode) {
        this.mode = mode;
        this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PortalDetailExplanationPreviewComponent;