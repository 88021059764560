import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.card.offer');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-component-card-offer',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/component.card.offer/view.scss */
.title {
  font-size: 16px;
  font-weight: bold;
  color: #9496A1;
}

.text {
  font: normal normal normal 16px/25px SUIT;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.card {
  width: 290px;
  height: 117px;
  border-radius: 10px;
  border: none;
}`],
})
export class ComponentCardOfferComponent implements OnInit {
    @Input() title: any;
    @Input() text: any;

    public async ngOnInit() {
    }
}

export default ComponentCardOfferComponent;