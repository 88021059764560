import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dashboard.survey');
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';
import { OnInit, OnChanges, Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-dashboard-survey',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dashboard.survey/view.scss */
.container-full {
  min-width: 1920px;
  height: 100%;
  overflow: auto;
}
.container-full .container-sub {
  width: 100%;
  background: no-repeat center/100% url("/assets/images/dashboard/bubble.png");
}
.container-full .container-sub .container-image {
  width: 1780px;
  height: 430px;
  margin-left: auto;
  margin-right: auto;
}
.container-full .container-sub .container-image .content {
  height: 100%;
  width: 100%;
}
.container-full .container-sub .container-image .content .content-menu {
  padding-top: 112px;
}
.container-full .container-sub .container-image .content .content-menu-name {
  padding-top: 15px;
}
.container-full .container-sub .container-image .content .content-content {
  padding-top: 30px;
  width: 922px;
}
.container-full .container-sub .container-image .content .content-button {
  margin-top: 30px;
}
.container-full .container-sub .container-image .content .content-button .btn-data {
  border-radius: 999px;
  background: #4F40F1;
  font-family: "MAIN-M";
  color: #FFFFFF;
  width: 166px;
  height: 40px;
  border: 0px;
}
.container-full .container-sub .nav-tabs.nav-fill {
  width: 1780px;
  margin: 0 auto;
}
.container-full .container-sub .nav-tabs.nav-fill .nav-link {
  border-radius: 20px 20px 0 0;
  border-bottom: none;
  cursor: pointer;
  background: #EDEEF9;
  color: #777986;
  font-size: 24px;
  font-weight: "MAIN-M";
}
.container-full .container-sub .nav-tabs.nav-fill .nav-link.active {
  border-bottom: none;
  background: #F7F7FA;
  color: #4F40F1;
  font-size: 24px;
  font-family: "MAIN-B";
  text-align: center;
}
.container-full .container-sub2 {
  width: 100%;
  background: #F7F7FA;
}
.container-full .container-sub2 .data-information {
  width: 1780px;
  height: 100%;
  background: white;
  margin: auto;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 24px #E3E6EC;
}

.text-menu {
  color: #5B5B6D;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-menu-name {
  color: black;
  font-size: 52px;
  font-weight: bold;
  font-family: "dev-B";
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-content {
  color: #404252;
  font-size: 22px;
  line-height: 180%;
  word-break: keep-all;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.offcanvas {
  width: 30%;
  overflow: auto;
}
.offcanvas .nav-tabs.nav-fill .nav-link {
  border-bottom: none;
  cursor: pointer;
  background: #F7F7F8;
  color: #777986;
  font-weight: "MAIN-M";
}
.offcanvas .nav-tabs.nav-fill .show {
  border-bottom: none;
  background: white;
  color: black;
  font-family: "MAIN-B";
  text-align: center;
}
.offcanvas .nav-tabs.nav-fill .nav-close {
  display: flex;
  align-items: center;
  border-bottom: none;
  cursor: pointer;
  padding: 0px 12px;
  background: #F7F7F8;
}
.offcanvas .form-group th {
  background: #C9C5F0;
  color: white;
  font-weight: bold;
  font-size: 12px;
}
.offcanvas .form-group .form-check-label {
  font-weight: bold;
}
.offcanvas .warn {
  margin: 4px 10px;
  color: red;
}
.offcanvas textarea.form-control {
  height: auto;
}`],
})
export class PortalDashboardSurveyComponent implements OnInit {

    public down = false;
    public category = {
        "device": "기기",
        "blood": "혈자리",
        "medicine": "한약재",
        "multiple": "연계"
    };

    public category_ls = {
        id: "",
        title: "",
        category: "",
        content: ""
    }
    public dataset_ls = []
    public dataset_id: any;

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }

    public dataset: any = { title: '건강검진 데이터셋' };
    public tab = "share";
    public use = true;
    public session: any;

    public period = [2020, 2021, 2022];
    public sex = ["여자", "남자"];
    public age = ["20-", "20-29", "30-39", "40-49", "50-59", "60+"];
    public sasang = ["태양인", "태음인", "소양인", "소음인"];
    public weight1: any;
    public weight2: any;
    public height1: any;
    public height2: any;
    public filter = {
        period: {},
        age: {},
        sex: {},
        sasang: {},
        height1: "",
        height2: "",
        weight1: "",
        weight2: "",
    };

    public conditions = [
        { title: "신장 소숫점 자리 제거", en: "point" },
        { title: "몸무게 소숫점 자리 제거", en: "mmi" },
        { title: "성별 제거", en: "sex" },
        { title: "연령대 제거", en: "agegroup" },
        { title: "신장 제거", en: "height" },
        { title: "몸무게 제거", en: "weight" }
    ]

    public conFilter = {
        point: "", sex: "", agegroup: "", height: "", weight: "", mmi: "", detail: '',
    };

    public async ngOnInit() {
        await this.service.init();
        this.session = this.service.auth.session;
        if (Object.keys(this.session).length === 0) this.use = false;
        await this.load();
        await this.service.render();
    }

    public async load() {
        this.dataset_id = this.router.url.split('/')[2];
        let { code, data } = await wiz.call("load", { "id": "설문" });
        // this.dataset_ls = data.dataset_row;
        this.category = data.dashboard_row;
        await this.service.render();
    }

    public setting = 'hospital';

    public page(setting) {
        this.setting = setting;
        if (setting === "hospital") {
            this.dataset.title = '건강검진 데이터셋';
        }
        else if (setting === "app") {
            this.dataset.title = '라이프로그 데이터셋';
        }
        this.service.render();
    }

    private async change(mode) {
        if (mode === "list") {
            this.tab = "list";
            this.listMode = "list";
        }
        else if (mode === "share") {
            this.tab = "share";
        }
        else {
            let message = "tab을 바꾸는 과정에서 오류가 발생했습니다. 다시 시도해 주십시오."
            await this.alert(message);
        }
        await this.service.render();
    }


    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }

    public async share() {
        this.down = true;
        await this.service.render();
    }

    public async close() {
        this.down = false;
        await this.service.render();
    }

}

export default PortalDashboardSurveyComponent;