import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.explaination.information');
import { OnInit, OnChanges, Input, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-detail-explaination-information',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.detail.explaination.information/view.scss */
span {
  font-size: 24px;
  font-family: "MAIN-B";
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}

.button_control {
  position: relative;
  margin: 30px 0 0 5px;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  box-shadow: none;
}
.button_control:hover {
  background-color: rgba(255, 255, 255, 0);
}
.button_control:hover .tooltip {
  opacity: 1;
}
.button_control i {
  display: inline-block;
  vertical-align: top;
  opacity: 0.3;
}
.button_control .tooltip {
  position: absolute;
  top: -60px;
  left: 150px;
  padding: 5px 12px;
  height: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 15px;
  line-height: 27px;
  white-space: nowrap;
  transition: opacity 0.2s;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}

img {
  width: 1720px;
  margin-left: 25px;
  margin-top: 30px;
}

.table {
  width: 1700px;
  max-height: 320px;
  font-size: 16px;
  font-family: "MAIN-M";
  margin-left: 30px;
  margin-top: 20px;
  border-radius: 10px;
}
.table tbody {
  background: none;
  vertical-align: middle;
}
.table tbody tr {
  max-height: 40px;
}
.table tbody th {
  width: 200px;
  max-height: 40px;
  background: #EDEEF8;
}
.table tbody td {
  width: 660px;
}`],
})
export class PortalDetailExplainationInformationComponent implements OnInit, OnChanges {
    @Input() list: any;
    @Input() version: any;
    @Input() page: any;
    @Input() num: any;

    public device = [
        { dataID: "NQkDpJRs556TdhQNhOt27rTq73hIwi8H", twenty: 2162, twentyOne: 2877 },
        { dataID: "0RGMl1iTiVRBybgO5KctY9278KyPyTqz", twenty: 2166, twentyOne: 2976 },
        { dataID: "wIYqq8BmlyrD5s9Hgvnscs1BtfFzg1mj", twenty: 4171, twentyOne: 5075 },
        { dataID: "zlKlhKGO8VBMegjlOMPX1L40Q76Wlgzn", twenty: 8601, twentyOne: 5619 },
        { dataID: "k1pkjQJFhmmC9sSXCt23g4SAvKYtVDp1", twenty: 0, twentyOne: 0 },
        { dataID: "JxxbJ7YbimGEqEBX8o9dcxPqoVwIN2a2", twenty: 4085, twentyOne: 5560 },
        { dataID: "VMv8qhqBHaX1VSPtYL0miG9MuH6v7mkv", twenty: 4216, twentyOne: 5709 },
        { dataID: "dZP3akmc8URGs3yaC7hUsWykAbRxuhA1", twenty: 4178, twentyOne: 5422 },
        { dataID: "CsPwskEJAKEwZIOYgOadK6cQ4FiowvBf", twenty: 2086, twentyOne: 2834 },
        { dataID: "gdpOpwt7nywS2fr4SADlmDF1lhgV0EZB", twenty: 4178, twentyOne: 5422 },
        { dataID: "w5sJndsv3o7FCmNCq2K1b4P6Z4v0BrSG", twenty: 12927, twentyOne: 165 },
        { dataID: "exZVO96BOWuB8wnUiOVslVUaeSCb72UE", twenty: 0, twentyOne: 0 },
        { dataID: "1u6bIfRh0eEszIGuOjMDgYGatbciEWnG", twenty: 0, twentyOne: 0 },
        { dataID: "yd5m3OpaRuAbm2dfRyh7ks2LT4e5vqsZ", twenty: 4301, twentyOne: 5390 },
        { dataID: "gUz3zXDvXdTx1yjoiJonX6IuiLotqYJ3", twenty: 0, twentyOne: 0 },
        { dataID: "ejc8xtTEcDsVjymnj3FbDREE3rmC9jez", twenty: 2188, twentyOne: 2891 },
        { dataID: "Giesy5Us4z6EekMlx12HcAfalV1JYasA", twenty: 0, twentyOne: 0 },
        { dataID: "lJaO0MGFyPzmEKfshZ6ZkTjhY5d3kXAa", twenty: 2140, twentyOne: 2815 },
        { dataID: "2YLwsLLl2IbVwxpwIzsaOxPycIJL6Lds", twenty: 2176, twentyOne: 2890 },
        { dataID: "hcaiajhxaBmT4SSN0eXqHckUab1TEEQD", twenty: 2159, twentyOne: 2888 },
        { dataID: "qQOZMBE97uB862HIxsYsPkoBqJszWrPM", twenty: 4329, twentyOne: 5715 },
    ]
    public dataRow: any;
    public deviceRow: any;
    public dataInfo: any;
    public deviceInfo: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        if (this.page == 'device') {
            await this.service.init();
            await this.file_load();
            await this.file_load2();
            await this.service.render();
        }
        else {
            await this.service.init();
            await this.file_load2();
            await this.service.render();
        }
    }

    public async ngOnChanges({ version }) {
        if (!version) return;
        const { currentValue } = version;

        if (['all', 'raw', 'cleansing', 'synthetic'].includes(currentValue)) {
            this.version = currentValue;
            this.file_load();
            this.file_load2();
        }
    }

    public async file_load() {
        this.dataset_id = WizRoute.segment.id;
        this.category = WizRoute.segment.category;
        let { code, data } = await wiz.call("file_info", { "id": this.dataset_id, "category": this.category, "version": this.version });
        this.dataInfo = data.dataInfo;
        this.deviceInfo = data.deviceInfo;

        // if (this.version == "synthetic") this.dataRow = this.dataInfo["length"];
        // else this.dataRow = this.dataInfo['all']['row'];

        this.deviceRow = this.deviceInfo["all"];
        for (let item of this.device) {
            if (item.dataID == this.dataset_id) {
                if (this.version == "all") {
                    this.deviceRow = this.deviceInfo["all"] + item.twenty + item.twentyOne;
                    break
                } else if (this.version == "cleansing") {
                    this.deviceRow = item.twenty + item.twentyOne;
                    break
                } else if (this.version == "synthetic") {
                    this.deviceRow = "-";
                    break;
                }
            }
        }
        await this.service.render();
    }


    public async file_load2() {
        if (this.list.datatype == '기기') {
            const { code, data } = await wiz.call("device_info", { datacode: this.list.datacode, version: this.version });
            this.dataRow = data.sum_info
            await this.service.render();
        } else if (this.list.datatype == '설문') {
            this.dataset_id = WizRoute.segment.id;
            let { code, data } = await wiz.call("survey_info", { 'dataset_id': this.dataset_id });
            this.dataRow = data.sum_info;
            await this.service.render();
        }
    }

}

export default PortalDetailExplainationInformationComponent;