import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.pages');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'wiz-page-pages',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/page.pages/view.scss */
.loading-wrap {
  display: flex;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}`],
})
export class PagePagesComponent implements OnInit {
    private _loading = false;

    constructor(@Inject( Service)         public service: Service,@Inject( ActivatedRoute)         public route: ActivatedRoute,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loading(true);
        this.route.url.subscribe(([_, { path }]) => {
            this.pages(path);
        })
        // await this.loading(false);
    }

    public async pages(path) {
        let { code, data } = await wiz.call('pages', { 'page': path });
        this.html = data.content;
        await this.loading(false);
        // await this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PagePagesComponent;