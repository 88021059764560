import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.survey.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-survey-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.survey.list/view.scss */
/* src/app/portal.survey.list/view.scss: no such file or directory */`],
})
export class PortalSurveyListComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        let session = this.service.auth.session;
        if (Object.keys(session).length === 0 || session.role != "admin") {
            this.service.href(`/`);
            return;
        }
        this.userRole = session.role;
        await this.load();
        await this.service.render();
    }

    public async load() {
        let { code, data } = await wiz.call("load");
        if (code == 200) {
            this.list = data;
        }
    }

    public async add() {
        await this.service.href(`/survey/edit/new`);
    }

    public async edit(id) {
        await this.service.href(`/survey/edit/${id}`);
    }

    public async result(id) {
        await this.service.href(`/survey/result/${id}`);
    }
}

export default PortalSurveyListComponent;