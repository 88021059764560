import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.search');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-search',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/component.search/view.scss */
.search-bar {
  border: 3px solid #4F40F1;
  border-radius: 30px;
  background: #FFFFFF;
  width: 100%;
  height: 60px;
}
.search-bar i {
  width: 18px;
  height: 18px;
}
.search-bar .form-control {
  border-radius: 50px 0 0 50px;
  border-right: none;
  padding-left: 25px;
  z-index: 3;
  border-color: #4F40F1;
  box-shadow: none;
}
.search-bar .btn-search {
  border-radius: 0 50px 50px 0 !important;
  border-left: none;
  border-color: #4F40F1;
  z-index: 3;
}
.search-bar .autocomplete-wrap {
  display: none;
  position: absolute;
  top: 0px;
  border: 3px solid #4F40F1;
  border-radius: 30px !important;
  background-color: white;
  margin-top: 0px;
  width: 100%;
  max-height: 30rem;
  color: black;
  text-align: left;
  z-index: 2;
}
.search-bar .autocomplete-wrap .box {
  overflow: auto;
  max-height: 20rem;
}
.search-bar .autocomplete-wrap .autocomplete-item {
  line-height: 3rem;
  font-size: 1rem;
  text-indent: 10px;
  cursor: pointer;
  border-radius: 34px;
}
.search-bar .autocomplete-wrap .autocomplete-item.current-dataset {
  font-weight: bold;
  background-color: #E3E6EC;
}
.search-bar input[type=text]:focus ~ .autocomplete-wrap {
  display: block;
}

.recommendation {
  margin-top: 19px;
  margin-left: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  opacity: 1;
}
@media (max-width: 768px) {
  .recommendation {
    display: none;
  }
}

.btn-tag {
  margin-bottom: 3px;
  height: 28px;
  width: auto;
  border: 1px solid #B0B0E8;
  border-radius: 7px;
  opacity: 1;
  background: white;
  color: #B0B0E8;
  font-size: 14px;
}

.btn-tag:hover {
  border: 1px solid #503FF1;
  color: #503FF1;
}`],
})
export class ComponentSearchComponent implements OnInit {
    private searchText = "";
    private list = [];
    private tags = [];
    private filtered = [];
    private currentIndex = null;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.getList();
        await this.getTag();
    }

    private async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    private async getList() {
        let { code, data } = await wiz.call("wrap");
        if (code !== 200) {
            let msg = "검색바를 불러오는 과정에서 오류가 발생했습니다.";
            await this.alert(msg);
            return;
        }
        this.list = data;
        this.filtered = data.slice(0, 10);
        await this.service.render();
    }

    private async getTag() {
        const { code, data } = await wiz.call("tag");
        if (code !== 200) {
            let msg = "추천 검색어를 불러오는 과정에서 오류가 발생했습니다.";
            await this.alert(msg);
            return;
        }
        
        let indices = Array.from({length: data.length}, (_, i) => i);
        indices = this.shuffle(indices);

        const cnt = 4;
        for (let i = 0; i < cnt; i++) {
            this.tags.push(data[indices[i]]);
        }

        await this.service.render();
    }

    private shuffle(arr) {
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr;
    }

    private async onSearch() {
        const { code } = await wiz.call("log", { text: this.searchText });
        if (code !== 200) {
            let msg = "검색 과정에서 오류 발생!";
            await this.alert(msg);
            return;
        }

        let url = "/dataset/search";
        if (this.searchText)
            url += "?text=" + this.searchText;
        this.onBlur();
        this.service.href(url);
    }

    private async doSearch() {
        if (this.searchText) {
            this.filtered = this.list.filter((item) => item.includes(this.searchText));
            this.filtered = this.filtered.slice(0, 10);
            await this.service.render();
        }
    }

    private async onKeyDown(event: KeyboardEvent) {
        let key = event.key;
        let len = this.filtered.length;

        switch (key) {
            case "ArrowDown":
                if (this.currentIndex === null) {
                    this.currentIndex = 0;
                }
                else {
                    if (this.currentIndex === len - 1) {
                        this.currentIndex === null;
                    }
                    else {
                        this.currentIndex = (this.currentIndex + len + 1) % len;
                    }
                }
                break;

            case "ArrowUp":
                if (this.currentIndex === null) {
                    this.currentIndex = len - 1;
                }
                else {
                    if (this.currentIndex === 0) {
                        this.currentIndex = null;
                    }
                    else {
                        this.currentIndex = (this.currentIndex + len - 1) % len;
                    }
                }
                break;

            case "Escape":
                document.activeElement.blur();
                return;

            case "Enter":
                if (event.isComposing)
                    return;
                if (this.currentIndex != null)
                    this.searchText = this.filtered[this.currentIndex];
                this.currentIndex === null;
                await this.onSearch();
                break;

            default:
                return;
        }

        let target = document.querySelectorAll(".autocomplete-item")[this.currentIndex];
        if (target && target.scrollIntoView) {
            target.scrollIntoView(false);
        }
        await this.service.render();
    }

    private async onClick(idx) {
        this.searchText = this.filtered[idx];
        await this.onSearch();
    }

    private async recommend(tag) {
        this.searchText = tag;
        await this.onSearch();
    }

    private async currentSearch(idx) {
        this.currentIndex = idx;
        await this.service.render();
    }

    private async onBlur() {
        const element = document.querySelector('.form-control') as HTMLElemnt;
        element.blur();
    }
}

export default ComponentSearchComponent;