import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.macro.report.krdhdtnb');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-macro-report-krdhdtnb',
template: templateSource || '',
    styles: [``],
})
export class PortalMacroReportKrdhdtnbComponent implements OnInit {
    @Input() path: any;
    public images: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loading(true);
        await this.load();
        await this.loading(false);
    }

    public async load() {
        let { code, data } = await wiz.call("load", { path: this.path });
        if (code != 200) {
            return
        }
        this.images = data;
        await this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PortalMacroReportKrdhdtnbComponent;