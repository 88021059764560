import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.macro.renderer');
import { Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-macro-renderer',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.macro.renderer/view.scss */
/* src/app/portal.macro.renderer/view.scss: no such file or directory */`],
})
export class PortalMacroRendererComponent {
    @Input() target: any;
    @Input() path: any;
}

export default PortalMacroRendererComponent;