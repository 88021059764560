import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.explaination.yearly');
import { OnInit, OnChanges, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'wiz-portal-detail-explaination-yearly',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.detail.explaination.yearly/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}

img {
  width: 809px;
  height: 459px;
}`],
})
export class PortalDetailExplainationYearlyComponent implements OnInit, OnChanges {
    @Input() version: any;

    public colors = ['#DFDEF7', '#C9C5F0', '#B0B0E8', '#4F40F1', '#362BA6','#0A0064']
    public myChart: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.dataset_id = WizRoute.segment.id;
        await this.num_info();
        await this.loadFile();
        await this.loadGraph();
    }

    public async ngOnChanges({ version }) {
        if (!version) return;
        const { previousValue, currentValue } = version;
        if (['all', 'raw', 'cleansing', 'synthetic'].includes(currentValue)) {
            this.version = currentValue;
            if (previousValue != null) {
                await this.myChart.destroy();
                await this.service.render();
                await this.loadFile();
                await this.loadGraph();
            }
        }
    }

    public results: any;
    public result: any;
    public async num_info() {
        const { code, data } = await wiz.call("fileInfoDB", { "id": this.dataset_id });
        this.results = data;
    }

    public async loadFile() {
        this.result = this.results[this.version]

        if (this.version == 'all') {
            const indexAll = this.result.findIndex(item => item.year === 'all');
            const indexSyn = this.result.findIndex(item => item.year === '재현');

            
            if (indexAll !== -1 && indexSyn !== -1) {
                const allItem = this.result.splice(indexAll, 1)[0]; // indexAll 항목을 잘라내서 allItem에 저장
                this.result.push(allItem); // allItem을 배열의 맨 끝에 추가

                // indexSyn을 다시 찾아서 처리
                const newIndexSyn = this.result.findIndex(item => item.year === '재현');
                if (newIndexSyn !== -1) {
                const synItem = this.result.splice(newIndexSyn, 1)[0]; // newIndexSyn 항목을 잘라내서 synItem에 저장
                this.result.splice(this.result.length - 1, 0, synItem); // synItem을 indexAll 앞에 삽입
                }
            }
        }
 
        this.labels = [];
        this.datas = [];

        this.result.forEach(item => {
            this.labels.push(item.year);
            this.datas.push(item.row);
        });

    }


    public async loadFile_orig() {
        this.dataset_id = WizRoute.segment.id;
        let { code, data } = await wiz.call("fileInfo", { "id": this.dataset_id, "version": this.version });

        if (code == 200) {
            this.files = Object.values(data);
            this.datas = [];
            this.labels = [];
            if (this.version == "synthetic") {
                this.labels.push("ALL")
                this.datas.push(this.files[2])
            } else {
                for (let file of this.files) {
                    this.labels.push(file.year)
                    this.datas.push(file.row)
                }
            }
        }

        await this.service.render();
    }

    public async loadGraph() {


        const data = {
            labels: this.labels,
            datasets: [{
                label: "데이터 건수",
                data: this.datas,
                backgroundColor: this.colors,
                borderColor: this.colors,
                borderWidth: 1,
                borderRadius: 7,
            }]
        };

        let maxValue = Math.max(...this.datas);
        let roundUnit;

        if (maxValue > 10000) {
            roundUnit = 2000;
        } else if (maxValue > 1000) {
            roundUnit = 1000;
        } else if (maxValue > 100) {
            roundUnit = 100;
        } else {
            roundUnit = 10;
        }

        maxValue = Math.ceil(maxValue / roundUnit) * roundUnit + roundUnit;


        const config = {
            type: 'bar',
            data: data,
            options: {
                // indexAxis: 'y',
                responsive: false,
                maxBarThickness: 100,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: '#6F6F6F',
                        align: '-90',
                        anchor: 'end',
                    },
                },
                scales: {
                    y: {
                        max: maxValue
                    }
                }
            },
            plugins: [ChartDataLabels],
        };
        if (this.version == "synthetic") config.options.indexAxis = 'y';

        const elmt = document.getElementById('widget-bar-yearly');
        if (elmt) {
            const ctx = elmt.getContext('2d');
            this.myChart = new Chart(ctx, config);
            this.myChart = this.myChart;
        }
        await this.service.render();
    }
}

export default PortalDetailExplainationYearlyComponent;