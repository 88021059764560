import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.graph.network');
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-graph-network',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/component.graph.network/view.scss */
.network-graph {
  margin-top: 30px;
  width: 1460px;
  height: 678px;
}
.network-graph .card {
  background: #EDEEF8;
  box-shadow: 0px 3px 24px #E3E6EC;
  border-radius: 10px;
  opacity: 1;
  height: 678px;
  border: none;
}
.network-graph .card .card-header {
  background: white;
  height: 49px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.network-graph .card .card-header .btn-data {
  border-radius: 999px;
  background: #4F40F1;
  font: normal normal bold 14px/18px SUIT;
  color: #FFFFFF;
  width: auto;
  height: 34px;
  border: 0px;
}
.network-graph .card .card-header .btn-none {
  border-radius: 999px;
  background: white;
  font: normal normal medium 14px/18px SUIT;
  color: #9496A1;
  width: auto;
  height: 34px;
  border: 0px;
}
.network-graph .card img {
  position: relative;
}
.network-graph .card .btn-text {
  position: absolute;
  background-color: transparent;
  border: none;
  font-family: "MAIN-M";
  font-size: 16px;
  color: black;
  border-radius: 50%;
  height: 100px;
  width: 100px;
}`],
})
export class ComponentGraphNetworkComponent implements OnInit {
    public dataset: string = "기기 데이터셋";
    public list = ['기기 데이터셋', '혈자리 데이터셋', '한약재 데이터셋', '의료기관 데이터셋'];
    private device = [];

    constructor(@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.load();
        await this.service.render();
    }

    private async load() {
        const { code, data } = await wiz.call("load");
        this.device = data;
    }

    private async change(mode) {
        this.dataset = mode;
        await this.service.render();
    }

    private async onClick(idx) {
        if (idx == 100) {
            await this.service.href(`/dashboard/medicine`);
        } else {
            await this.service.href(this.device[idx].url);
        }
    }
}

export default ComponentGraphNetworkComponent;