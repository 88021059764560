import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.explanation.datacard');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-detail-explanation-datacard',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.detail.explanation.datacard/view.scss */
.container-data {
  margin-top: 30px;
}
.container-data span {
  font-size: 24px;
  font-family: "MAIN-B";
  color: black;
  margin-top: 30px;
  margin-left: 80px;
}

.card {
  height: 193px;
  border: 2px solid white;
  box-shadow: 0px 3px 24px #E3E6EC;
  border-radius: 10px;
  width: 100%;
  align-items: center;
  flex-direction: row;
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 30px;
  font-family: "MAIN-B";
}

.sub {
  text-align: left;
  font-size: 20px;
  color: #777986;
  font-family: "MAIN-N";
}

i::before {
  width: 58px;
  height: 58px;
  color: #4F40F1;
}

.test {
  align-items: center;
  justify-content: center;
}`],
})
export class PortalDetailExplanationDatacardComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public category: any;
    public category_ls = {
        "device": "기기",
        "blood": "혈자리",
        "herbal_medicine": "한약재",
        "medical_institution": "의료기관",
        "decoction": "탕약"
    };

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async load() {
        this.category = WizRoute.segment.category;
        let { code, data } = await wiz.call("load", { category: this.category_ls[this.category] });
        if (code != 200) return;
        this.list = data;
    }

    public async dataset(id) {
        // this.service.href(`/dashboard/${this.category}/${id}`);
        location.href = "/dashboard/" + this.category + "/" + id;
    }
}

export default PortalDetailExplanationDatacardComponent;