import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.survey.graph');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-detail-survey-graph',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.detail.survey.graph/view.scss */
.data-graph {
  width: 1300px;
  height: 100%;
  border-radius: 10px;
}
.data-graph .form-select {
  border: 2px solid #4F40F1;
  border-radius: 20px;
  text-align: center;
}

.data-filter {
  width: 483px;
  background: #E5E2F5;
  box-shadow: 0px 3px 24px #E3E6EC;
  border-radius: 10px;
  position: relative;
}
.data-filter .filter {
  width: 400px;
  margin: 50px;
}
.data-filter .btn-refresh {
  margin-left: auto;
}
.data-filter .form-label {
  font-size: 18px;
  font-weight: "MAIN-B";
}

.rotate-animation {
  animation: rotateAnimation 0.4s linear;
}`],
})
export class PortalDetailSurveyGraphComponent implements OnInit {

    private isAnimating = false;
    private _loading = false;

    public IDs = [];
    public info = [];
    public category = "QS01.구갈구건";
    public QSitems = [];
    public result = [];


    public hospital = [
        { name: "가천대학교 한방병원", id: "GC", count: 0 },
        { name: "부산대학교 한방병원", id: "PS", count: 0 },
        { name: "나주 동신대학교 한방병원", id: "DS", count: 0 },
        { name: "동국대학교 한방병원", id: "DG", count: 0 },
        { name: "대전대학교 한방병원", id: "DJ", count: 0 }
    ]

    public years = [
        { name: "2020", id: "20-", count: 0 },
        { name: "2021", id: "21-", count: 0 },
        { name: "2022", id: "22-", count: 0 }
    ]
    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        this.IDs = data.titles;
        this.info = data.info;

        await this.analysis();
    }

    public async change(category) {
        this.category = category;
        await this.loading(true);
        await this.analysis();
        await this.loading(false);
    }

    public data = {
        num: [],
        label: []
    };
    public color = ['#4F40F1', '#B0B0E8', '#F2748D', '#ED4264', '#F2A3B3', '#3FBE7A', '#1FA2FF', '#12D8FA', '#FF794F']
    public charts = [];

    public async analysis() {

        this.data.label = [];
        this.data.num = [];

        this.charts.forEach(obj => { obj.destroy(); })
        this.charts = [];

        this.QSitems = this.info.filter(row => row.TABLECAT.includes(this.category) && row.numbering === 1);
        const varorig = this.QSitems.map(row => row.VARORIG)

        await this.service.render();

        const { code, data } = await wiz.call("analysis", { "category": this.category, "varorig": JSON.stringify(varorig) });
        this.result = data.result;

        this.result.forEach(item => {
            Object.values(item).forEach(innerItem => {
                this.data.label.push(Object.keys(innerItem));
                this.data.num.push(Object.values(innerItem))
            });
        });


        for (let i = 0; i < this.data.label.length; i++) {
            const length = this.data.label[i].length;

            let graphType = "bar";
            let positionType = "bottom";

            if (length == 2) {
                graphType = "doughnut";
                positionType = "right";
            };

            const labels = this.data.label[i]

            let color = [...this.color].sort(() => Math.random() - 0.5);
            let graph = {
                labels: labels,
                datasets: [{
                    data: this.data.num[i],
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 1
                }]
            };

            let config = {
                type: graphType,
                data: graph,
                options: {
                    responsive: false,
                    plugins: {
                        legend: {
                            onClick: null,
                            position: positionType,
                            maxWidth: 300,
                            labels: {
                                boxWidth: 10,
                                padding: 15,
                                generateLabels: function (chart) {
                                    let dataset = chart.data.datasets[0];
                                    // let labels = chart.data.labels.map((label, index) => {
                                    //     return {
                                    //         text: `${label} (${dataset.data[index]})`, // 라벨 텍스트 커스터마이징
                                    //         fillStyle: dataset.backgroundColor[index], // 배경색 설정
                                    //         strokeStyle: dataset.borderColor[index], // 테두리색 설정
                                    //         lineWidth: dataset.borderWidth, // 테두리 너비 설정
                                    //         hidden: false, // 기본적으로 보임
                                    //         index: index // 인덱스 설정
                                    //     };
                                    // });
                                    // return labels;
                                    const total = dataset.data.reduce((a, b) => a + b, 0);
                                    if (total == 0) {
                                        return labels.map((label, index) => {
                                            const percentage = 0;
                                            return {
                                                text: ` ${label}:   ${percentage}%`,
                                                fillStyle: dataset.backgroundColor[index]
                                            };
                                        });
                                    } else {
                                        return labels.map((label, index) => {
                                            const value = dataset.data[index];
                                            const percentage = ((value / total) * 100).toFixed(2);
                                            return {
                                                text: ` ${label} :   ${percentage}%`,
                                                fillStyle: dataset.backgroundColor[index]
                                            };
                                        });
                                    }
                                }
                            },
                        }
                    }
                },
            }

            const elmt = document.getElementById('myChart-' + this.category + "-" + i);
            if (elmt) {
                let ctx = elmt.getContext('2d');
                let myChart = new Chart(ctx, config);
                this.charts.push(myChart);
            }
        }
        await this.service.render();

    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PortalDetailSurveyGraphComponent;