import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.share');
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-share',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/page.share/view.scss */
.container {
  max-width: 1024px;
  width: 100%;
}`],
})
export class PageShareComponent implements OnInit {

    public mode = '';
    public role = '';
    public id = '';

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,@Inject( ActivatedRoute)         private route: ActivatedRoute,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.setting();
        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                await this.setting();
            }
        })
    }

    public async setting() {
        this.mode = WizRoute.segment.mode;
        this.role = WizRoute.segment.role;
        this.id = WizRoute.segment.id;
        await this.service.render();
    }
}

export default PageShareComponent;