import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dataset.graph.elqeifhl');
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-portal-dataset-graph-elqeifhl',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dataset.graph.elqeifhl/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}`],
})
export class PortalDatasetGraphElqeifhlComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadFile();
        await this.loadGraph();
        // ejc8xtTEcDsVjymnj3FbDREE3rmC9jez
    }

    public async loadFile() {
        this.dataset_id = WizRoute.segment.id;
        let { code, data } = await wiz.call("fileInfo", { "id": this.dataset_id });
        this.labels = data.labels;
        this.means = data.means;
        await this.service.render();
    }

    public async loadGraph() {
        const data = {
            labels: this.labels,
            datasets: [{
                label: "체온",
                data: this.means,
                backgroundColor: ["#FFEBEE", "#EF9A9A", "#EF5350"],
                borderColor: ["#FFEBEE", "#EF9A9A", "#EF5350"],
                borderWidth: 1,
                borderRadius: 7,
            }]
        };

        const config = {
            type: 'bar',
            data: data,
            options: {
                responsive: false,
                maxBarThickness: 100,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: '#6F6F6F',
                        align: '-90',
                        anchor: 'end',
                    },
                }
            },
        };

        const elmt = document.getElementById('widget-graph');
        if (elmt) {
            const ctx = elmt.getContext('2d');
            let myChart = new Chart(ctx, config);
        }
        await this.service.render();
    }
}

export default PortalDatasetGraphElqeifhlComponent;