import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.chart.bar.left');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-component-chart-bar-left',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/component.chart.bar.left/view.scss */
.card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}`],
})
export class ComponentChartBarLeftComponent implements OnInit {
    // @Input() title: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        const data = {
            labels: ['우로빌리노겐', '포도당', '빌리루빈', '케론체', '요비중', '잠혈', 'Ph', '요단백', '요아질산염', '백혈구'],
            datasets: [{
                label: '데이터 상세보기',
                data: [40, 87, 60, 20, 52, 10, 30, 72, 52, 70],
                backgroundColor: [
                    '#4F40F1',
                    '#4F40F1',
                    '#4F40F1',
                    '#4F40F1',
                    '#4F40F1'
                ],
                borderColor: [
                    '#4F40F1',
                    '#4F40F1',
                    '#4F40F1',
                    '#4F40F1',
                    '#4F40F1'
                ],
                borderWidth: 1,
                borderRadius: 10,
            }]
        };

        const config = {
            type: 'bar',
            data: data,
            options: {
                indexAxis: 'y',
                responsive: false,
                maxBarThickness: 15,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                            stepSize: 25
                        }
                    }
                }
            },
        };
        const elmt = document.getElementById('widget-bar-left');
        if (elmt) {
            const ctx = elmt.getContext('2d');

            let myChart = new Chart(ctx, config);

        }
        await this.service.render();
    }
}

export default ComponentChartBarLeftComponent;