import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dataset.graph.uogbpzan');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-dataset-graph-uogbpzan',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dataset.graph.uogbpzan/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}`],
})
export class PortalDatasetGraphUogbpzanComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadFile();
        await this.loadGraph();
    }

    public async loadFile() {
        this.dataset_id = WizRoute.segment.id;
        if (!this.dataset_id) {
            this.dataset_id = "hcaiajhxaBmT4SSN0eXqHckUab1TEEQD";
        }
        let { code, data } = await wiz.call("fileInfo", { "id": this.dataset_id });
        if (code == 200) {
            this.labels = data.labels;
            this.weight = data.weight_means;
            this.bmi = data.bmi_means;
        }
        await this.service.render();
    }

    public async loadGraph() {
        const data = {
            labels: this.labels,
            datasets: [{
                // label: '데이터 상세보기',
                data: this.weight,
                backgroundColor: [
                    'rgb(242, 116, 141, 0.2)'
                ],
                borderColor: [
                    '#F2748D'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                data: this.bmi,
                backgroundColor: [
                    'rgb(79, 64, 241, 0.2)'
                ],
                borderColor: [
                    '#4F40F1'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
                yAxisID: 'y2'
            }]
        };

        const config = {
            type: 'line',
            data: data,
            options: {
                responsive: false,
                maxBarThickness: 40,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                            stepSize: 25
                        },
                        title: {
                            display: true,
                            text: '몸무게(kg)'
                        }
                    },
                    y2: {
                        suggestedMin: 0,
                        suggestedMax: 30,
                        ticks: {
                            stepSize: 5
                        },
                        title: {
                            display: true,
                            text: 'BMI'
                        },
                        position: 'right'
                    },
                    x: {
                        title: {
                            display: true,
                            text: '신장(cm)'
                        }
                    }
                }
            },
        };

        const elmt = document.getElementById('widget-whb');
        if (elmt) {
            
            const ctx = elmt.getContext('2d');
            let myChart = new Chart(ctx, config);
        }
        await this.service.render();
    }
}

export default PortalDatasetGraphUogbpzanComponent;