import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dataset.graph.ofuzpqtt');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-dataset-graph-ofuzpqtt',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dataset.graph.ofuzpqtt/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}`],
})
export class PortalDatasetGraphOfuzpqttComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadFile();
        await this.loadGraph();
    }

    public async loadFile() {
        this.dataset_id = WizRoute.segment.id;
        let { code, data } = await wiz.call("fileInfo", { "id": this.dataset_id });
        if (code == 200) {
            this.labels = data.labels;
            this.front_avg = data.front_avg;
            this.rear_avg = data.rear_avg;
        }
        await this.service.render();
    }

    public async loadGraph() {
        const data = {
            labels: this.labels,
            datasets: [{
                label: '앞 평균온도',
                data: this.front_avg,
                backgroundColor: [
                    '#B9B9DD'
                ],
                borderColor: [
                    '#B9B9DD'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 1,
                borderRadius: 7,
            }, {
                label: '뒤 평균온도',
                data: this.rear_avg,
                backgroundColor: [
                    '#EDEEF8'
                ],
                borderColor: [
                    '#EDEEF8'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 1,
                borderRadius: 7,
            }]
        };

        const config = {
            type: 'bar',
            data: data,
            options: {
                responsive: false,
                maxBarThickness: 40,
                indexAxis: 'y',
                plugins: {
                    legend: {
                        display: true,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                    },
                },
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 30,
                        ticks: {
                            stepSize: 5
                        },
                        title: {
                            display: true,
                            text: '성별'
                        }
                    },
                    x: {
                        title: {
                            display: true,
                            text: '평균온도'
                        }
                    }
                }
            },
        };

        const elmt = document.getElementById('widget-graph');
        if (elmt) {
            const ctx = elmt.getContext('2d');
            let myChart = new Chart(ctx, config);
        }
        await this.service.render();
    }
}

export default PortalDatasetGraphOfuzpqttComponent;