import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.community.edit');
import { OnInit, ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import toastr from 'toastr';
import ClassicEditor from "src/libs/ckeditor/ckeditor";

@Component({
    selector: 'wiz-portal-community-edit',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.community.edit/view.scss */
.ck-editor__editable {
  min-height: 200px;
}

.col-md-2 {
  display: flex;
  align-items: center;
}
.col-md-2 h4 {
  margin-bottom: 0px;
}

.info-form.first-child {
  border-top: 2px solid #000000;
}

.info-form {
  padding: 12px 0;
  border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}

.row {
  --tblr-gutter-x: 1rem;
  --tblr-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--tblr-gutter-y));
  margin-right: calc(-0.5 * var(--tblr-gutter-x));
  margin-left: calc(-0.5 * var(--tblr-gutter-x));
}

textarea {
  width: 100%;
  min-height: 300px;
  resize: none;
}`],
})
export class PortalCommunityEditComponent implements OnInit {
    private post = {
        id: "",
        title: "",
        priority: "",
        content: "",
        files: []
    };
    private login = {};
    private category = "";

    private status = {
        upload: false,
        upload_process: '0',
    };

    @ViewChild('editor')
    public editorElement: ElementRef;
    public editor: any;
    public Editor = ClassicEditor;

    public fd = new FormData();
    public files: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/");

        this.category = WizRoute.segment.category;
        if (Object.keys(this.service.auth.session).length === 0 || (this.category == "notice" && this.service.auth.session.role != 'admin')) {
            this.service.href(`/`);
            return;
        }
        await this.load();
        await this.buildEditor();
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    private async load() {
        this.post.id = WizRoute.segment.id;
        this.category = WizRoute.segment.category;
        if (this.post.id !== 'new') {
            let { code, data } = await wiz.call('load', { id: this.post.id })
            if (code !== 200) {
                await this.alert("ERROR!", "error")
            }

            const { post, login } = data;
            this.post = post;
            this.post.files = JSON.parse(post.files.replace(/'/g, '"'));
            this.login = login;

            if (this.service.auth.session.id != this.post.user_id) {
                await this.service.href(`/`);
                return;
            }
            await this.service.render();
        }
    }

    private async update() {
        if (this.editor.data.get() == "") {
            await this.alert("글을 작성해주세요.");
            return;
        }
        let copy = this.post;
        copy.content = this.editor.data.get();
        if (WizRoute.segment.id !== 'new') {
            this.fd.append("data", JSON.stringify(copy))
            let url = wiz.url('update')
            const { code, data } = await this.service.file.upload(url, this.fd);
        }
        else {
            copy.category = this.category;
            this.fd.append("data", JSON.stringify(copy))
            let url = wiz.url('create')
            const { code, data } = await this.service.file.upload(url, this.fd);
        }

        this.service.href('/community/' + this.category);
    }

    private async del() {
        let res = await this.alert("게시물을 삭제하겠습니까?", "error", "취소");

        if (!res)
            return;

        let { code } = await wiz.call('delete', { id: this.post.id });
        if (code == 200) {
            this.service.href('/community/' + this.category);
        }
        else {
            alert('ERROR!');
        }
    }

    public async buildEditor() {
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'todoList',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                ]
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/community/upload/' + this.post.id
            }
        });
        this.editor.data.set(this.post.content);
    }

    // File 관련 기능
    private async fileSelect(event) {
        this.files = event.target.files;
    }

    private async upload() {
        for (let i = 0; i < this.files.length; i++) {
            let file = this.files[i]
            if (!file.filepath) file.filepath = file.name;
            this.fd.append('file[]', file);
            this.post.files.push(file.filepath);
        }
        await this.service.render();
    }

    private async delete_file(item) {
        let files = this.post.files
        let index = files.indexOf(item);
        files.splice(index, 1)
        this.post.files = files;
        await this.service.render();
    }
}

export default PortalCommunityEditComponent;