import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.survey.analysis');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-survey-analysis',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.survey.analysis/view.scss */
.form-select {
  border: 2px solid #4F40F1;
  border-radius: 20px;
  text-align: center;
}

.chevron {
  color: #4F40F1;
  border: none;
  background: none;
}

.search-bar {
  border: 1px solid #4F40F1;
  border-radius: 30px;
  background: #FFFFFF;
  width: 100%;
}
.search-bar i {
  width: 18px;
  height: 18px;
}
.search-bar .form-control {
  border-radius: 50px 0 0 50px;
  border-right: none;
  padding-left: 25px;
  z-index: 3;
  border-color: #4F40F1;
  box-shadow: none;
}
.search-bar .btn-search {
  border-radius: 0 50px 50px 0 !important;
  border-left: none;
  border-color: #4F40F1;
  z-index: 3;
}
.search-bar .autocomplete-wrap {
  display: none;
  position: absolute;
  top: 0px;
  border: 1px solid #4F40F1;
  border-radius: 30px !important;
  background-color: white;
  margin-top: 0px;
  width: 100%;
  max-height: 30rem;
  color: black;
  text-align: left;
  z-index: 2;
}
.search-bar .autocomplete-wrap .box {
  overflow: auto;
  max-height: 20rem;
}
.search-bar .autocomplete-wrap .autocomplete-item {
  line-height: 3rem;
  font-size: 1rem;
  text-indent: 10px;
  cursor: pointer;
  border-radius: 34px;
}
.search-bar .autocomplete-wrap .autocomplete-item.current-dataset {
  font-weight: bold;
  background-color: #E3E6EC;
}
.search-bar input[type=text]:focus ~ .autocomplete-wrap {
  display: block;
}

.container-upload {
  width: 100%;
  max-width: 1024px;
  margin: auto;
}
.container-upload .btn-upload {
  background: #C9C5F0;
  border-radius: 10px;
  font-weight: bold;
}

.container-graph .title h1 {
  color: #777986;
}
.container-graph .title .card {
  width: 100%;
  text-align: center;
}
.container-graph .card {
  border-radius: 10px;
}
.container-graph .card-body canvas {
  margin: auto;
}
.container-graph .btn-page {
  border-radius: 20px;
  background-color: #4F40F1;
  color: white;
  font-weight: bold;
}
.container-graph .card-footer:last-child {
  border-radius: 0 0 10px 10px;
}

.loading-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: 1000;
  pointer-events: none;
}`],
})
export class PortalSurveyAnalysisComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public color = ['#4F40F1', '#B0B0E8', '#F2748D', '#ED4264', '#F2A3B3', '#3FBE7A', '#1FA2FF', '#12D8FA', '#FF794F']

    public surveyID: any;
    public loadFile = false;
    public filepath: any;
    public filename: any;
    public surveyID: any;
    public analyze = false;
    public preview: any;

    public page = 0;
    public total = 0;
    public charts = [];

    public async ngOnInit() {
        await this.service.init();
        await this.loading(true);
        await this.load();
        await this.loading(false);
    }

    public async load() {

        let { code, data } = await wiz.call("load");
        this.surveyID = data;
        await this.service.render();
    }

    public async upload() {
        let files = await this.service.file.select({ accept: '.csv,.xlsx' });
        let fd = new FormData();
        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = files[i].name;
            fd.append('file[]', files[i], files[i].name);
            this.filename = files[i].name;
        }
        await this.service.render();

        if (files) {
            this.loadFile = true;
        }

        fd.append("surveyID", this.surveyID);

        let url = wiz.url('upload');
        let { code, data } = await this.service.file.upload(url, fd);
        if (code != 200) {
            await this.alert("파일 업로드 중 오류가 발생했습니다")
            return;
        }
        this.filepath = data;
        await this.service.render();
    }


    public async removeFile() {
        let res = await this.alert("삭제하시겠습니까?", "error", "취소");
        if (res) {
            let { code, data } = await wiz.call("removeFile", { filepath: this.filepath })
            if (code != 200) return
        }
        await this.alert("삭제되었습니다.", "success");
        await this.service.render();
        this.loadFile = false;
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public dataGroup: any;
    public dataSet = '';

    public async analysis() {

        let res = await this.alert("데이터양에 따라 파일 분석 소요시간이 길어질 수 있습니다. 진행하시겠습니까?", "success", "취소", "분석하기");

        if (!res) return;

        await this.loading(true);
        const { code, data } = await wiz.call("fileInfo", { "filepath": this.filepath, "filename": this.filename });
        await this.service.render();
        // let { code, data } = wiz.call("analysis", { "filepath": this.filepath, "filename": this.filename });
        if (code == 201) {
            await this.alert("파일이 존재하지 않습니다.", "error");
            return
        }
        this.analyze = true;
        this.preview = data;
        this.dataGroup = Object.keys(this.preview);
        this.dataSet = this.dataGroup[0];
        this.total = this.dataGroup.length - 1;
        await this.graph();
        await this.loading(false);
        await this.service.render();

    }


    public view: any;
    public async graph() {

        this.view = this.preview[this.dataSet]
        let item = this.preview[this.dataSet]
        await this.service.render();

        for (let i = 0; i < item.contents.length; i++) {
            if (item.labels[i][0] == 'NONE') {
                item.labels[i].shift()
                item.data[i].shift()
                await this.service.render();
            }

            let color = [...this.color].sort(() => Math.random() - 0.5);

            const modifiedLabels = item.labels[i].map(label => {
                if (label.length > 5) {
                    return label.slice(0, 5) + '...';
                }
                return label;
            });

            let graph = {
                labels: modifiedLabels,
                datasets: [{
                    data: item.data[i],
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 1
                }]
            };

            let graphType = "";
            let positionType = "right";
            if (item.labels[i].length == 1) graphType = "pie"
            else if (item.labels[i].length == 2) graphType = "doughnut"
            else if (item.labels[i].length >= 3 && item.labels[i].length <= 5) graphType = "polarArea";
            else {
                graphType = "bar";
                positionType = "bottom";
            }

            let config = {
                type: graphType,
                data: graph,
                options: {
                    responsive: false,
                    plugins: {
                        legend: {
                            onClick: null,
                            position: positionType,
                            maxWidth: 300,
                            labels: {
                                boxWidth: 10,
                                padding: 15,
                                generateLabels: function (chart) {
                                    // const labels = chart.data.labels;
                                    const labels = item.labels[i];
                                    const dataset = chart.data.datasets[0];
                                    const total = dataset.data.reduce((a, b) => a + b, 0);
                                    if (total == 0) {
                                        return labels.map((label, index) => {
                                            const percentage = 0;
                                            return {
                                                text: ` ${label}:   ${percentage}%`,
                                                fillStyle: dataset.backgroundColor[index]
                                            };
                                        });
                                    } else {
                                        return labels.map((label, index) => {
                                            const value = dataset.data[index];
                                            const percentage = ((value / total) * 100).toFixed(2);
                                            return {
                                                text: ` ${label} :   ${percentage}%`,
                                                fillStyle: dataset.backgroundColor[index]
                                            };
                                        });
                                    }
                                }
                            },
                        }
                    }
                },
            };
            const elmt = document.getElementById('widget-survey-' + this.dataSet + '-' + i);

            if (elmt) {
                let ctx = elmt.getContext('2d');
                let myChart = new Chart(ctx, config);
                this.charts.push(myChart);
            }
        }
        await this.service.render();
    }

    public async next() {

        if (this.page == this.total) {
            await this.alert("마지막 페이지입니다.", "error");
            return
        }
        this.page = this.page + 1;
        this.dataSet = this.dataGroup[this.page]
        this.charts.forEach(obj => {
            obj.destroy();
        })
        this.charts = [];
        await this.graph();
        await this.service.render();

    }

    public async prev() {

        if (this.page == 0) {
            await this.alert("첫번째 페이지입니다.", "error");
            return
        }
        this.page = this.page - 1;
        this.dataSet = this.dataGroup[this.page]
        this.charts.forEach(obj => {
            obj.destroy();
        })
        this.charts = [];

        await this.graph();
        await this.service.render();
    }

    public word = "";
    public filtered = [];
    public currentIndex = null;

    public async search() {
        let nowIdx = this.dataGroup.indexOf(this.dataSet);
        let index = this.dataGroup.indexOf(this.word);
        if (nowIdx == index) {
            this.page = index;
            this.word = "";
            this.filtered = [];
            return;
        }
        this.dataSet = this.dataGroup[index]
        if (index == -1) {
            await this.alert("풀네임으로 검색해주세요", "error");
            return;
        }

        this.page = index;
        this.word = "";
        this.filtered = [];

        await this.graph();
    }

    public async doSearch() {
        if (this.word) {
            this.filtered = this.dataGroup.filter((item) => item.includes(this.word));
            await this.service.render();
        }
    }

    public async onClick(idx) {
        this.word = this.filtered[idx];
        await this.search();
    }

    public async currentSearch(idx) {
        this.currentIndex = idx;
        await this.service.render();
    }

    private async onKeyDown(event: KeyboardEvent) {
        let key = event.key;
        let len = this.filtered.length;

        switch (key) {
            case "ArrowDown":
                if (this.currentIndex === null) {
                    this.currentIndex = 0;
                }
                else {
                    if (this.currentIndex === len - 1) {
                        this.currentIndex === null;
                    }
                    else {
                        this.currentIndex = (this.currentIndex + len + 1) % len;
                    }
                }
                break;

            case "ArrowUp":
                if (this.currentIndex === null) {
                    this.currentIndex = len - 1;
                }
                else {
                    if (this.currentIndex === 0) {
                        this.currentIndex = null;
                    }
                    else {
                        this.currentIndex = (this.currentIndex + len - 1) % len;
                    }
                }
                break;

            case "Escape":
                document.activeElement.blur();
                return;

            case "Enter":
                if (event.isComposing) {
                    return;
                }
                if (this.currentIndex != null){
                    this.word = this.filtered[this.currentIndex];
                    this.currentIndex = null;
                    // await this.search();
                }
                // } else {
                //     await this.alert("풀네임으로 검색해주세요", "error");
                //     // toastr.error(`풀네임으로 검색해주세요`);
                //     console.log('test')
                //     return;
                // }
                break;

            default:
                return;
        }

        let target = document.querySelectorAll(".autocomplete-item")[this.currentIndex];
        if (target && target.scrollIntoView) {
            target.scrollIntoView(false);
        }
        await this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }



}

export default PortalSurveyAnalysisComponent;