import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dataset.graph.yzimkzit');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

@Component({
    selector: 'wiz-portal-dataset-graph-yzimkzit',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dataset.graph.yzimkzit/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}`],
})
export class PortalDatasetGraphYzimkzitComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadFile();
        await this.loadGraph();
    }

    public async loadFile() {
        this.dataset_id = WizRoute.segment.id;
        if (!this.dataset_id) {
            this.dataset_id = "Giesy5Us4z6EekMlx12HcAfalV1JYasA";
        }
        let { code, data } = await wiz.call("fileInfo", { "id": this.dataset_id });
        this.labels = data.label;
        this.datas = data.value;
        this.colors = ['#F8F6D2', '#FFFBBA', '#FFF772', '#FEF428', '#FFED5C', '#FEEA2F', '#D6B866', '#8A7934'];
        await this.service.render();
    }

    public async loadGraph() {
        const total = this.datas.reduce((a, b) => a + b, 0);

        const data = {
            labels: this.labels,
            datasets: [{
                label: '소변의 색 비율',
                data: this.datas,
                datalabels: { anchor: 'center' },
                backgroundColor: this.colors,
                borderColor: this.colors,
                borderWidth: 1,
                pointStyle: 'circle'
            }]
        };

        const config = {
            type: 'doughnut',
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            usePointStyle: true,
                        }
                    },
                    // title: {
                    //     display: true,
                    //     text: 'Total - ' + total,
                    //     align: 'end',
                    //     font: { size: '18px' }
                    // },
                    datalabels: {
                        font: {
                            size: '14px'
                        },
                        padding: 6,
                        formatter: (value) => {
                            const percentage = (value / total) * 100;
                            if (percentage < 3) return '';
                            return percentage.toFixed(1) + '%';
                        },
                    },
                },
            },
        };

        const actions = [
            {
                name: 'Toggle Point Style',
                handler(myChart) {
                    myChart.options.plugins.legend.labels.usePointStyle = !myChart.options.plugins.legend.labels.usePointStyle;
                    myChart.update();
                }
            },
        ];
        const elmt = document.getElementById('widget-pee');
        if (elmt){
            const ctx = elmt.getContext('2d');
            let myChart = new Chart(ctx, config);
        }
        await this.service.render();
    }
}

export default PortalDatasetGraphYzimkzitComponent;